import Plausible from 'plausible-tracker'
import useRequest from '@/composables/request'
import useAuthUser from '@/composables/authUser'

import { inject } from 'vue'

// Use a rollup to combine global and tenant-specific plausible metrics in separate sites
const hostname_parts = location.hostname.split('.')
let generic_hostname = null
if (hostname_parts[hostname_parts.length - 1] === 'localhost') {
    generic_hostname = 'localhost'
} else if (hostname_parts.length > 2) {
    generic_hostname = hostname_parts.slice(-2).join('.')
}
const domain = generic_hostname ? `${location.hostname},${generic_hostname}` : location.hostname

const { trackPageview: plausibleTrackPageview, trackEvent: plausibleTrackEvent } = Plausible({
    domain: domain,
    apiHost: location.origin,
    trackLocalhost: true,
})

const useTracker = () => {
    const curUser = useAuthUser()
    const curRequest = useRequest()
    const config = inject('config')
    const isEnabled = config.ENABLE_PLAUSIBLE
    const trackPageview = (data = {}, options = {}) => {
        if (!isEnabled) return
        plausibleTrackPageview(data, {
            ...options,
            props: {
                request_ref: curRequest.getRequestRef?.value,
                owner: curRequest.data?.owner?.is_self,
                has_submitter_role: curUser?.hasRole('submitter'),
                borrower: curRequest.data?.borrower?.is_self,
                has_borrower_role: curUser?.hasRole('borrower'),
                valuer: curRequest.data?.valuer?.is_self,
                has_valuer_role: curUser?.hasRole('valuer'),
                module: curRequest.data?.valuation_type,
            },
        })
    }
    const trackEvent = (data = {}, options = {}) => {
        if (!isEnabled) return
        plausibleTrackEvent(data, {
            ...options,
            props: {
                request_ref: curRequest.getRequestRef?.value,
                owner: curRequest.data?.owner?.is_self,
                has_submitter_role: curUser?.hasRole('submitter'),
                borrower: curRequest.data?.borrower?.is_self,
                has_borrower_role: curUser?.hasRole('borrower'),
                valuer: curRequest.data?.valuer?.is_self,
                has_valuer_role: curUser?.hasRole('valuer'),
                module: curRequest.data?.valuation_type,
            },
        })
    }
    return { trackPageview, trackEvent }
}

export default useTracker
